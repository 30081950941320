import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useGlobalScrollLock from "../../hooks/useGlobalScrollLock";
import useAxios, { configure } from "axios-hooks";
import { $authHost, axiosConfig } from "../../common/api";
import { isNumeric } from "../../common/utils";
import ModalWallet from "./ModalWallet";

const ModalWithdrawal = ({ active, setActive, shop }) => {
  const [modalActive, setModalActive] = useState(false);
  const [amountWallet, setAmountWallet] = useState("");
  const [methodPurseWallet, setMethodPurseWallet] = useState("");
  const [methodNameWallet, setMethodNameWallet] = useState("Wallet");

  const { shopId } = useParams();

  useGlobalScrollLock(active);

  configure({ axios: $authHost });

  const [{ loading }, executePost] = useAxios(
    {
      url: `/merchant/shop/withdraws/${shopId}/create`,
      method: "POST",
    },
    axiosConfig
  );

  let errors = {
    withdraw_amount: isNumeric(amountWallet)
      ? null
      : {
          message: "Допустимы только цифры",
        },
  };

  const getErrorBlock = (error) => {
    return error ? (
      <span
        style={{
          color: "red",
          position: "absolute",
          bottom: -25,
        }}
      >
        {error?.message}
      </span>
    ) : null;
  };

  useEffect(() => {
    if (!active) {
      setAmountWallet("");
      setMethodPurseWallet("");
      setMethodNameWallet("Wallet");
    }
  }, [active, setAmountWallet, setMethodPurseWallet, setMethodNameWallet]);

  const handleSubmit = async () => {
    await executePost({
      data: {
        amount: amountWallet,
        method_name: methodNameWallet,
        method_purse: methodPurseWallet,
      },
    })
      .then((data) => {
        setActive(false);
        toast.success("Заявка успешно добавлена");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error);
      });
  };

  const handleChangeAmountWallet = (value) => {
    setAmountWallet(value.toString().replace(/,/g, "."));
  };

  const handleClick = (e) => {
    if (!modalActive) {
      setActive(false);
    }
  };

  return (
    <div
      className={active ? "modal shown" : "modal"}
      id="modal2"
      onClick={handleClick}
    >
      <div className="dialog" onClick={(e) => e.stopPropagation(e)}>
        <div className="dialog__head">
          <div className="dialog__head-title">Вывод средств</div>
          <div
            className="dialog__head-close"
            id="modal2Close"
            onClick={() => setActive(false)}
          >
            <svg
              width="27"
              height="16"
              viewBox="0 0 27 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.630296 7.49551C0.239771 7.88604 0.239771 8.5192 0.630296 8.90973L6.99426 15.2737C7.38478 15.6642 8.01795 15.6642 8.40847 15.2737C8.79899 14.8832 8.79899 14.25 8.40847 13.8595L2.75162 8.20262L8.40847 2.54577C8.79899 2.15524 8.79899 1.52208 8.40847 1.13155C8.01795 0.741029 7.38478 0.741029 6.99426 1.13155L0.630296 7.49551ZM26.3374 7.20262H1.3374V9.20262H26.3374V7.20262Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div className="dialog__body">
          <div className="custom-input custom-input_block">
            <label htmlFor="withdraw-amount">Сумма</label>
            <input
              value={amountWallet}
              type="text"
              id="withdraw-amount"
              placeholder="Введите данные"
              autoComplete="off"
              onChange={(e) => handleChangeAmountWallet(e.target.value)}
              style={{ marginRight: 120 }}
            />
            <button
              className="btn btn-success btn_sm custom-input_block-btn"
              onClick={() => {
                handleChangeAmountWallet(shop?.balance);
              }}
            >
              Вся сумма
            </button>
            {getErrorBlock(errors?.withdraw_amount)}
          </div>

          <div className="custom-input custom-input_block no-border">
            <label htmlFor="withdraw-address">Выберите метод</label>
            <button
              onClick={() => setModalActive(true)}
              className="select-btn"
              id="select-modal3"
            >
              {methodNameWallet}
            </button>
          </div>

          {methodNameWallet !== "Wallet" ? (
            <div className="custom-input custom-input_block">
              <label htmlFor="withdraw-address">Реквизиты (Куда)</label>
              <input
                value={methodPurseWallet}
                type="text"
                id="withdraw-address"
                autoComplete="off"
                placeholder="Введите данные"
                onChange={(e) => setMethodPurseWallet(e.target.value)}
              />
            </div>
          ) : (
            <div style={{ marginTop: 10 }}>
              <span>Средства будут отправлены на внутренний кошелек</span>
            </div>
          )}

          <div className="mt-50">
            <button
              disabled={loading}
              className="btn btn-success btn_sm"
              onClick={() => {
                handleSubmit(
                  shopId,
                  amountWallet,
                  methodNameWallet,
                  methodPurseWallet
                );
              }}
            >
              Создать
            </button>
          </div>
        </div>
      </div>

      <ModalWallet
        active={modalActive}
        setActive={setModalActive}
        setMethodNameWallet={setMethodNameWallet}
      />
    </div>
  );
};

export default ModalWithdrawal;
