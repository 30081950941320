import React, { useEffect, useState } from "react";
import useAxios, { configure } from "axios-hooks";
import AnimatedBlock from "../animatedblock/AnimatedBlock";
import Spinner from "../spinner/Spinner";
import useGlobalScrollLock from "../../hooks/useGlobalScrollLock";
import { $authHost, axiosConfig } from "../../common/api";

const ModalStatic = ({ active, setActive, id }) => {
  const [recall, setRecall] = useState(false);
  configure({ axios: $authHost });

  useGlobalScrollLock(active);

  const [{ data, loading }, refetch] = useAxios(
    `/merchant/shop/payment/${id}/logs` + (recall ? "?call=true" : ""),
    axiosConfig
  );

  const handleOnCall = async () => {
    setRecall(true);
    refetch({
      data: {
        call: true,
      },
    }).catch((error) => {
      // console.log("refetch", error);
    });
  };

  const handleOnClose = () => {
    setRecall(false);
    setActive(false);
  };

  useEffect(() => {
    if (active) {
      refetch().catch((error) => {
        // console.log("refetch", error);
      });
    }
  }, [active, refetch]);

  return (
    <div
      className={active ? "modal shown" : "modal"}
      id="modal1"
      onClick={handleOnClose}
    >
      <div className="dialog" onClick={(e) => e.stopPropagation(e)}>
        <div className="dialog__head">
          <div className="dialog__head-title">Логи</div>
          {data && (
            <button
              disabled={loading}
              className="dialog__head-action btn btn-success btn_minimal"
              onClick={handleOnCall}
            >
              Повторить коллбек
            </button>
          )}

          <div
            className="dialog__head-close"
            id="modal1Close"
            onClick={handleOnClose}
          >
            <svg
              width="27"
              height="16"
              viewBox="0 0 27 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.630296 7.49551C0.239771 7.88604 0.239771 8.5192 0.630296 8.90973L6.99426 15.2737C7.38478 15.6642 8.01795 15.6642 8.40847 15.2737C8.79899 14.8832 8.79899 14.25 8.40847 13.8595L2.75162 8.20262L8.40847 2.54577C8.79899 2.15524 8.79899 1.52208 8.40847 1.13155C8.01795 0.741029 7.38478 0.741029 6.99426 1.13155L0.630296 7.49551ZM26.3374 7.20262H1.3374V9.20262H26.3374V7.20262Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        {loading ? (
          <div style={{ marginTop: 50 }}>
            <Spinner />
          </div>
        ) : (
          <AnimatedBlock>
            <div
              className="dialog__body"
              style={{
                textAlign: "left",
                overflow: "auto",
                maxHeight: "calc(100vh - 230px)",
              }}
            >
              {data?.log?.map((Itemlog) => (
                <div
                  className="card card_light"
                  key={Itemlog.id}
                  style={{
                    marginTop: 20,
                    textShadow: "1px 2px 1px black",
                  }}
                >
                  <div style={{ marginTop: 5 }}>
                    #{Itemlog.id} / {Itemlog.updatedAt.slice(0, 10)}
                  </div>

                  {Itemlog.is_success ? (
                    <div style={{ marginTop: 5, color: "#3aff00" }}>
                      Успешно
                    </div>
                  ) : (
                    <div style={{ marginTop: 5, color: "#ffe630" }}>FAIL</div>
                  )}
                  <div style={{ marginTop: 5 }}>{Itemlog.cb}</div>
                  <div style={{ marginTop: 5 }}>
                    {Itemlog.result} | CODE: {Itemlog.result_code}
                  </div>
                </div>
              ))}
            </div>
          </AnimatedBlock>
        )}
      </div>
    </div>
  );
};

export default ModalStatic;
