import React, { useState, useEffect } from "react";
import TextareaAutosize from "react-textarea-autosize";
import FileInputZone from "../fileinputzone/FileInputZone";
import useGlobalScrollLock from "../../hooks/useGlobalScrollLock";
import useAxios, { configure } from "axios-hooks";
import { toast } from "react-toastify";
import { $authHost, axiosConfig } from "../../common/api";

const ModalAddAppeal = ({ active, setActive, paymentId }) => {
  const [note, setNote] = useState("test");
  const [files, setFiles] = useState([]);

  const onDrop = (filesData) => {
    setFiles(filesData);
  };

  useGlobalScrollLock(active);

  configure({ axios: $authHost });

  const [{ loading }, executePost] = useAxios(
    {
      url: "/merchant/shop/appeals/create",
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    axiosConfig
  );

  useEffect(() => {
    if (!active) {
      setNote("");
      setFiles([]);
    }
  }, [active, setNote]);

  const submitModal = async () => {
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("payment_id", paymentId);
    formData.append("note", note);

    await executePost({
      data: formData,
    })
      .then((data) => {
        setActive(false);
        toast.success("Апелляция успешно добавлена");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error);
      });
  };

  if (!paymentId) {
    return null;
  }

  return (
    <div
      className={active ? "modal shown" : "modal"}
      id="modal7"
      onClick={() => setActive(false)}
    >
      <div className="dialog" onClick={(e) => e.stopPropagation(e)}>
        <div className="dialog__head">
          <div className="dialog__head-title">Добавление апелляции</div>
          <div
            className="dialog__head-close"
            id="modal7Close"
            onClick={() => setActive(false)}
          >
            <svg
              width="27"
              height="16"
              viewBox="0 0 27 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.630296 7.49551C0.239771 7.88604 0.239771 8.5192 0.630296 8.90973L6.99426 15.2737C7.38478 15.6642 8.01795 15.6642 8.40847 15.2737C8.79899 14.8832 8.79899 14.25 8.40847 13.8595L2.75162 8.20262L8.40847 2.54577C8.79899 2.15524 8.79899 1.52208 8.40847 1.13155C8.01795 0.741029 7.38478 0.741029 6.99426 1.13155L0.630296 7.49551ZM26.3374 7.20262H1.3374V9.20262H26.3374V7.20262Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div
          className="dialog__body"
          style={{
            textAlign: "left",
            overflow: "auto",
            maxHeight: "calc(100vh - 230px)",
          }}
        >
          <div className="custom-input custom-input_block">
            <label htmlFor="appeal-payment-id">Id платежа</label>
            <input
              value={paymentId}
              disabled
              type="text"
              id="appeal-payment-id"
              autoComplete="off"
            />
          </div>

          <div className="custom-input custom-input_block">
            <label htmlFor="appeal-note">Комментарий</label>
            <TextareaAutosize
              name="appeal-note"
              id="appeal-note"
              style={{
                lineHeight: 1.5,
                paddingTop: 10,
                paddingBottom: 10,
                color: "white",
                backgroundColor: "transparent",
                border: "none",
                outline: "none",
              }}
              cacheMeasurements
              value={note}
              autoFocus={true}
              autoComplete="off"
              placeholder="Введите данные"
              onChange={(e) => setNote(e.target.value)}
            />
          </div>

          <FileInputZone onDrop={onDrop} filesData={files} />

          <div className="mt-50">
            <button
              disabled={loading}
              className="btn btn-success btn_sm"
              onClick={submitModal}
            >
              Добавить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAddAppeal;
