import React from "react";
import { useDropzone } from "react-dropzone";
import "./FileInputZone.css";

function FileInputZone({ onDrop, filesData }) {
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    minSize: 100,
    maxSize: 5242880,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "application/pdf": [".pdf"],
      "application/x-pdf": [".pdf"],
    },
    onError: (err) => {
      console.log("useDropzone error", err);
    },
    onDrop,
  });

  const files = filesData.map((file, index) => (
    <li key={index}>{file.path}</li>
  ));

  return (
    <section className="dropzoneContainer">
      {files.length > 0 && (
        <div className="dropzoneFileName">
          <ul>{files}</ul>
        </div>
      )}
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <div>
          Кликните или перетащите файл подтверждения платежа (не более 5 MB,
          png, jpg или pdf)
        </div>
        <button type="button" className="btn btn-primary">
          Выбрать файл
        </button>
      </div>
    </section>
  );
}

export default FileInputZone;
