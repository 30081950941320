import React, { useState, useEffect } from "react";
import ModalAddProject from "./modal/ModalAddProject";
import ShopBlockProject from "./ShopBlockProject";
import Spinner from "./spinner/Spinner";
import { AddShopPlusIcon } from "./Icons";

const ShopList = (props) => {
  const { data, loading, refetch } = props;
  const [modalActive, setModalActive] = useState(false);

  const handleModalActivity = (active = true) => {
    setModalActive(active);
  };

  useEffect(() => {
    if (!modalActive) {
      refetch().catch(() => {});
    }
  }, [modalActive]);

  return (
    <>
      <ModalAddProject active={modalActive} setActive={handleModalActivity} />
      <div className="main__head">
        <div
          className="text text_xl"
          style={{ textShadow: "1px 3px 1px black" }}
        >
          Текущие проекты
        </div>
        <button
          className="btn btn_add"
          id="add-project"
          onClick={handleModalActivity}
        >
          <AddShopPlusIcon />
        </button>
      </div>

      {loading ? (
        <Spinner />
      ) : (
        <div className="projects">
          {data?.shops &&
            data?.shops?.map((item) => (
              <ShopBlockProject item={item} key={item.id} />
            ))}
        </div>
      )}
    </>
  );
};

export default ShopList;
