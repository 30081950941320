import React, { useState, useEffect } from "react";
import useAxios, { configure } from "axios-hooks";
import { $authHost, axiosConfig } from "../common/api";
import { formatFinance } from "../common/utils";
import Pagination from "./Pagination";
import AnimatedBlock from "./animatedblock/AnimatedBlock";
import ModalWithdrawal from "./modal/ModalWithdrawal";
import Spinner from "./spinner/Spinner";
import { AddPlusIcon } from "./Icons";
import useDayjsFormat from "../hooks/useDayjsFormat";

const ShopWithdrawals = (props) => {
  const { shopId, shop } = props;

  const [pageWithdraws, setPageWithdraws] = useState(1);
  const count = 20;
  const [modalActive, setModalActive] = useState(false);

  const withdrawStatus = ["Ожидает", "Оплачено", "Отменено"];

  const getWithdrawStatus = (status) => {
    const index = parseInt(status);
    if (index >= 0 && index < withdrawStatus.length) {
      return withdrawStatus[index];
    } else {
      return "В архиве";
    }
  };

  const withdrawStyles = [
    "btn-innactive btn-xs btn-warning",
    "btn-innactive btn-xs btn-innactive_success",
    "btn-innactive btn-xs btn-innactive_error",
  ];

  const getWithdrawStyle = (status) => {
    const index = parseInt(status);
    if (index >= 0 && index < withdrawStyles.length) {
      return withdrawStyles[index];
    } else {
      return "btn-innactive btn-xs btn-innactive_draft";
    }
  };

  const { getFullDateMsk, getFullTimeMsk } = useDayjsFormat();

  configure({ axios: $authHost });

  const [{ data, loading }, executePost, cancelRequest] = useAxios(
    {
      url: `/merchant/shop/withdraws/${shopId}/list`,
      method: "POST",
    },
    axiosConfig
  );

  useEffect(() => {
    if (!modalActive) {
      executePost({
        data: {
          page: pageWithdraws,
          count: count,
        },
      }).catch((error) => {
        // console.log("executePost", error);
      });
    }
    return () => {
      cancelRequest();
    };
    // eslint-disable-next-line
  }, [pageWithdraws, count, modalActive]);

  let pageCountQuery = Math.ceil(data?.count_withdraws / count);

  if (loading)
    return (
      <div className="project-details__tab project-details__tab_active">
        <div className="project-details__table mt-30">
          <div style={{ display: "flex" }}>
            <Spinner />
          </div>
        </div>
      </div>
    );

  return (
    <>
      <div className="project-details__tab project-details__tab_active">
        <div className="project-details__table mt-30">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              alignContent: "center",
            }}
          >
            <AddPlusIcon onClick={() => setModalActive(true)} />
            {
              <AnimatedBlock>
                <table className="fixedtable">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Сумма</th>
                      <th>Куда</th>
                      <th>Система</th>
                      <th>Время</th>
                      <th>Статус</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.withdraws.map(
                      ({
                        id,
                        amount,
                        amount_to_pay,
                        method_purse,
                        method_name,
                        createdAt,
                        status,
                      }) => (
                        <tr key={id}>
                          <td data-label="ID">{id}</td>
                          <td data-label="Сумма">
                            {formatFinance(amount)}{" "}
                            <sup>{formatFinance(amount_to_pay)} ₽</sup>
                          </td>
                          <td data-label="Куда">{method_purse}</td>
                          <td data-label="Система">{method_name}</td>
                          <td data-label="Время">
                            {getFullTimeMsk(createdAt)} <br />
                            {getFullDateMsk(createdAt)}
                          </td>
                          <td data-label="Статус">
                            <span className={getWithdrawStyle(status)}>
                              {getWithdrawStatus(status)}
                            </span>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </AnimatedBlock>
            }
          </div>

          <ModalWithdrawal
            active={modalActive}
            setActive={setModalActive}
            shop={shop}
          />

          {
            <Pagination
              page={pageWithdraws}
              setPage={setPageWithdraws}
              pageCountQuery={pageCountQuery}
            />
          }
        </div>
      </div>
    </>
  );
};

export default ShopWithdrawals;
