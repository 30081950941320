import React, { useState, useEffect } from "react";
import useGlobalScrollLock from "../../hooks/useGlobalScrollLock";
import useAxios, { configure } from "axios-hooks";
import { toast } from "react-toastify";
import { $authHost, axiosConfig } from "../../common/api";

const ModalAddProject = ({ active, setActive }) => {
  const [name, setName] = useState("");
  const [addresPayment, setAddresPayment] = useState("");
  const [addresApi, setAddresApi] = useState("");

  useGlobalScrollLock(active);

  configure({ axios: $authHost });

  const [{ loading }, executePost] = useAxios(
    {
      url: "/merchant/shop/create",
      method: "POST",
    },
    axiosConfig
  );

  useEffect(() => {
    if (!active) {
      setName("");
      setAddresPayment("");
      setAddresApi("");
    }
  }, [active, setName, setAddresPayment, setAddresApi]);

  const submitModal = async () => {
    await executePost({
      data: {
        name: name,
        url_result: addresPayment,
        url_api: addresApi,
      },
    })
      .then((data) => {
        setActive(false);
        toast.success("Проект успешно добавлен");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error);
      });
  };

  return (
    <div
      className={active ? "modal shown" : "modal"}
      id="modal1"
      onClick={() => setActive(false)}
    >
      <div className="dialog" onClick={(e) => e.stopPropagation(e)}>
        <div className="dialog__head">
          <div className="dialog__head-title">Новый проект</div>
          <div
            className="dialog__head-close"
            id="modal1Close"
            onClick={() => setActive(false)}
          >
            <svg
              width="27"
              height="16"
              viewBox="0 0 27 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.630296 7.49551C0.239771 7.88604 0.239771 8.5192 0.630296 8.90973L6.99426 15.2737C7.38478 15.6642 8.01795 15.6642 8.40847 15.2737C8.79899 14.8832 8.79899 14.25 8.40847 13.8595L2.75162 8.20262L8.40847 2.54577C8.79899 2.15524 8.79899 1.52208 8.40847 1.13155C8.01795 0.741029 7.38478 0.741029 6.99426 1.13155L0.630296 7.49551ZM26.3374 7.20262H1.3374V9.20262H26.3374V7.20262Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div className="dialog__body">
          <div className="custom-input">
            <label htmlFor="project-id">Название проекта</label>
            <input
              value={name}
              type="text"
              id="project-id"
              autoComplete="off"
              placeholder="First Project"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="custom-input">
            <label htmlFor="project-url-success">URL страницы успешной оплаты</label>
            <input
              value={addresPayment}
              type="text"
              id="project-url-success"
              autoComplete="off"
              placeholder="https://example.com/success"
              onChange={(e) => setAddresPayment(e.target.value)}
            />
          </div>
          <div className="custom-input">
            <label htmlFor="project-api">URL API коллбека</label>
            <input
              value={addresApi}
              type="text"
              id="project-api"
              autoComplete="off"
              placeholder="https://example.com/webhook/callback"
              onChange={(e) => setAddresApi(e.target.value)}
            />
          </div>
          <div className="mt-50">
            <button
              disabled={loading}
              className="btn btn-success btn_sm"
              onClick={submitModal}
            >
              Создать
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAddProject;
