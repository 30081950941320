import React, { useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ModalStatic from "./modal/ModalStatic";
import { formatFinance, truncateString } from "../common/utils";
import useDayjsFormat from "../hooks/useDayjsFormat";
import iconMoney from "../assets/img/moneyIcon.png";
import iconClick from "../assets/img/handClick.png";
import ErrorMark from "../assets/img/CrossMark.png";
import { DislikeIcon, CallbackOkIcon, CallbackFailIcon } from "./Icons";

const ShopPaymentsBlock = ({ info, addAppeal }) => {
  const [modalStatic, setModalStatic] = useState(false);

  const { getFullDateMsk, getFullTimeMsk, isDeltaPartSecond } = useDayjsFormat();

  let btnInnactive;
  let result;
  let icon;
  if (info.status === 2) {
    btnInnactive = "btn-xs btn-innactive btn-innactive_success";
    result = "Оплачен";
    icon = iconMoney;
  } else if (info.status === 1) {
    btnInnactive = "btn-xs btn-innactive btn-innactive_success";
    result = "Оплачен";
    icon = ErrorMark;
  } else if (info.status === 0) {
    btnInnactive = "btn-xs btn-innactive btn-warning";
    result = "Ожидает";
    icon = null;
  } else if (info.status === -1) {
    btnInnactive = "btn-xs btn-innactive btn-innactive_draft";
    result = "Черновик";
    icon = null;
  } else if (info.status === -2) {
    btnInnactive = "btn-xs btn-innactive btn-innactive_draft";
    result = "Отменен";
    icon = null;
  }

  return (
    <tr>
      <td data-label="ID">{info?.id}</td>
      <td data-label="Order">{info?.merchant_id}</td>
      <td data-label="E-mail" data-tooltip-id={"tooltip-email" + info?.id}>
        {truncateString(info?.email)}
        <ReactTooltip
          id={"tooltip-email" + info?.id}
          offset={-15}
          content={info?.email}
        />
      </td>
      <td data-label="Сумма">{formatFinance(info?.amount)} ₽</td>
      <td data-label="Зачислено">{formatFinance(info?.amount_to_shop)} ₽</td>
      <td data-label="Система">{info?.method_group}</td>
      <td data-label="Pan">
        {info?.note?.pan && info?.note?.pan.length > 4
          ? "**" + info?.note?.pan.slice(-4)
          : ""}
      </td>
      <td data-label="Дата">
        {getFullTimeMsk(info?.createdAt) /* updatedAt */}
        <br />
        {getFullDateMsk(info?.createdAt)}
      </td>
      <td data-label="Оплата">
        {info.status > 0 && info?.receivedAt
          ? isDeltaPartSecond(info?.createdAt, info?.receivedAt)
          : null}
      </td>
      <td data-label="Статус">
        <span className={btnInnactive}>{result}</span>
      </td>
      <td
        data-label="Ответ"
        style={
          {
            //minHeight: 75,
          }
        }
      >
        {info.status > 0 && (
          <button
            style={{
              paddingTop: 7,
              paddingBottom: 1,
              paddingLeft: 7,
              paddingRight: 4,
            }}
            className={
              info?.status > 1
                ? "btn btn-xs btn-success btn_minimal"
                : "btn btn-xs btn-danger btn_minimal"
            }
            onClick={() => setModalStatic(true)}
          >
            {info?.status > 1 ? <CallbackOkIcon /> : <CallbackFailIcon />}
          </button>
        )}

        <ModalStatic
          active={modalStatic}
          setActive={setModalStatic}
          id={info.id}
        />
      </td>
      <td data-label="Апелляция">
        {(info.status === 0 || info.status === -2) && (
          <button
            style={{
              paddingTop: 5,
              paddingBottom: 4,
              paddingLeft: 7,
              paddingRight: 7,
            }}
            className="btn btn-xs btn-danger btn_minimal"
            onClick={() => {
              addAppeal(info?.id);
            }}
          >
            <DislikeIcon />
          </button>
        )}
      </td>
    </tr>
  );
};

export default ShopPaymentsBlock;
