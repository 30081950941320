import React, { useState, useEffect } from "react";
import useAxios, { configure } from "axios-hooks";
import Pagination from "./Pagination";
import ShopPaymentsBlock from "./ShopPaymentsBlock";
import Spinner from "./spinner/Spinner";
import { formatFinance } from "../common/utils";
import { $authHost, axiosConfig } from "../common/api";
import ModalAddAppeal from "./modal/ModalAddAppeal";
import AnimatedBlock from "./animatedblock/AnimatedBlock";

const ShopStatistics = (props) => {
  const { shopId, shop } = props;
  const [pageStatistics, setPageStatistics] = useState(1);
  const [optionValue, setOptionValue] = useState(10);
  const [valueSearchState, setValueSearchState] = useState("");
  const [optionValueStatus, setOptionValueStatus] = useState("");
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);

  const [modalActive, setModalActive] = useState(false);
  const [paymentId, setPaymentId] = useState(null);

  configure({ axios: $authHost });

  const [{ data, loading }, executePost, cancelRequest] = useAxios(
    {
      url: `/merchant/shop/${shopId}/payments`,
      method: "POST",
    },
    axiosConfig
  );

  useEffect(() => {
    executePost({
      data: {
        page: pageStatistics,
        count: optionValue,
        status: optionValueStatus,
        date_start: dateStart,
        date_end: dateEnd,
        order_id: valueSearchState,
      },
    }).catch((error) => {
      // console.log("executePost", error);
    });
    return () => {
      cancelRequest();
    };
    // eslint-disable-next-line
  }, [
    executePost,
    pageStatistics,
    optionValue,
    optionValueStatus,
    dateStart,
    dateEnd,
    valueSearchState,
  ]);

  const options = [
    { id: 1, value: 10 },
    { id: 2, value: 25 },
    { id: 3, value: 50 },
    { id: 4, value: 100 },
    { id: 5, value: 150 },
  ];

  // добавить статусы
  const optionsStatus = [
    { id: 1, value: null, name: "Любой" },
    { id: 2, value: 1, name: "Оплачен" },
    { id: 3, value: 0, name: "Ожидает" },
    { id: 4, value: -1, name: "Черновик" },
    { id: 5, value: -2, name: "Отменен" },
  ];

  const handleChangeOption = (e) => {
    setOptionValue(e.target.value);
    setPageStatistics(1);
  };

  const handleChangeStatus = (e) => {
    setOptionValueStatus(e.target.value);
    setPageStatistics(1);
  };

  const handleAddAppeal = (id) => {
    setPaymentId(id);
    setModalActive(true);
  };

  let pageCountQuery = Math.ceil(data?.count_payments / optionValue);

  return (
    <div className="project-details__tab project-details__tab_active">
      <AnimatedBlock>
        <div className="project-details__stats">
          <div
            className={
              loading ? "card card_light card__skeleton" : "card card_light"
            }
          >
            <div className="card__title">
              Общий баланс
              <br />
              кассы
            </div>
            <div className="card__text">{formatFinance(shop?.balance)} ₽</div>
          </div>
          <div className={loading ? "card card__skeleton_blue" : "card"}>
            <div className="card__title">
              Сумма выводов
              <br />с кассы
            </div>
            <div className="card__text">
              {formatFinance(shop?.total_payout)} ₽
            </div>
          </div>
          <div className={loading ? "card card__skeleton_blue" : "card"}>
            <div className="card__title">
              Сумма пополнений
              <br />
              на кассу
            </div>
            <div className="card__text">
              {formatFinance(shop?.total_income)} ₽
            </div>
          </div>
        </div>
      </AnimatedBlock>

      <div className="project-details__table">
        <div
          className="project-details__table-filter"
          style={{ alignItems: "center" }}
        >
          <input
            value={valueSearchState}
            className="input"
            type="text"
            placeholder="Поиск"
            onChange={(e) => {
              setValueSearchState(e.target.value);
            }}
          />
          <div
            className="d-flex gap-20"
            style={{ alignItems: "center", flexDirection: "column" }}
          >
            <span>Статус</span>
            <select onChange={handleChangeStatus}>
              {optionsStatus.map((item) => (
                <option key={item.id} value={item.value}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div
            className="d-flex gap-20"
            style={{ alignItems: "center", flexDirection: "column" }}
          >
            Начало
            <input
              onChange={(e) => {
                setDateStart(e.target.value);
              }}
              type="date"
            ></input>
          </div>
          <div
            className="d-flex gap-20"
            style={{ alignItems: "center", flexDirection: "column" }}
          >
            Конец
            <input
              onChange={(e) => {
                setDateEnd(e.target.value);
              }}
              type="date"
            ></input>
          </div>
          <div
            className="d-flex gap-20"
            style={{ alignItems: "center", flexDirection: "column" }}
          >
            <span>Количество</span>
            <select onChange={handleChangeOption}>
              {options.map((item) => (
                <option key={item.id} value={item.value}>
                  {item.value}
                </option>
              ))}
            </select>
          </div>
        </div>

        {loading ? (
          <div style={{ marginTop: 50 }}>
            <Spinner />
          </div>
        ) : (
          <AnimatedBlock>
            <table className="fixedtable">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Order</th>
                  <th>E-mail</th>
                  <th>Сумма</th>
                  <th>Зачислено</th>
                  <th>Система</th>
                  <th>Pan</th>
                  <th>Дата</th>
                  <th>Оплата</th>
                  <th>Статус</th>
                  <th>Ответ</th>
                  <th>Апелляция</th>
                </tr>
              </thead>
              <tbody>
                {data?.payments?.map((info) => (
                  <ShopPaymentsBlock
                    info={info}
                    key={info.id}
                    addAppeal={handleAddAppeal}
                  />
                ))}
              </tbody>
            </table>
          </AnimatedBlock>
        )}
        {loading ? null : (
          <Pagination
            page={pageStatistics}
            setPage={setPageStatistics}
            pageCountQuery={pageCountQuery}
          />
        )}
        <ModalAddAppeal
          active={modalActive}
          setActive={setModalActive}
          paymentId={paymentId}
        />
      </div>
    </div>
  );
};

export default ShopStatistics;
